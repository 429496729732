<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/payment.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
onfield_return.title : "ใบส่งมอบโดรนเลขที่ : {name}"
onfield_return.header.page_date : "เวลาที่พิมพ์: {datetime}"
onfield_return.header.company_info : "ศูนย์บริการ"
onfield_return.header.drone : "Drone"
onfield_return.header.drone.serial : "เลขเครื่อง: {serial}"
onfield_return.header.drone.pixhawk : "({pixhawk})"
onfield_return.header.drone.model : "รุ่น: {name}"
onfield_return.header.report_by : "รับแจ้งโดย"

onfield_return.header.drone_receive_date : "โดรนเข้าศูนย์วันที่"
onfield_return.header.devices : "อุปกรณ์ที่มากับ Drone"
onfield_return.sign_warning: "กรุณาลงลายมือชื่อผู้เข้ารับบริการ และผู้รับมอบให้ครบถ้วน"
onfield_return.header.devices_without_drone : "อุปกรณ์"
onfield_return.header.devices_unspecified: "ไม่ระบุ"

onfield_return.footer.consent.acceptance : "ข้าพเจ้าได้ตรวจสอบและยืนยันความถูกต้องของรายการข้างต้นเรียบร้อยแล้ว และรับมอบโดรนพร้อมอุปกรณ์เป็นที่เรียบร้อย"
onfield_return.footer.signature : "<div>..................................</div>"
onfield_return.footer.name : "<pre>(                  )</pre>"
onfield_return.footer.signature.customer : "ผู้เข้ารับบริการ"
onfield_return.footer.signature.inspect_by : "ผู้ตรวจสอบ"
onfield_return.footer.signature.deliver_by : "ผู้รับมอบ"
onfield_return.footer.signature.approve_by : "ผู้อนุมัติ"
onfield_return.footer.remark : "หมายเหตุ: "

onfield_return.return_by : "ทำเรื่องโดย"
onfield_return.return_to : "ส่งมอบให้กับ"
onfield_return.return_date : "วันที่ส่งมอบ"
onfield_return.return_method : "วิธีการส่งมอบ"
onfield_return.refer : "*งานนี้ถูกส่งมอบจากศูนย์ {sender} ไปยังศูนย์ {receiver}"

onfield_return.return.payment : "สถานะการชำระเงิน"
onfield_return.return.detail : "การรับเครื่องคืน"

onfield_return_payment.field.completed_date.display : "ชำระครบเมื่อวันที่ {date}"
onfield_return_modal.pageLoading.tip : "กำลัง Load ข้อมูล, กรุณารอสักครู่ ..."


</i18n>


<template>
	<a-modal centered width="800px" :visible="visible" :footer="null" :mask-closable="false" @cancel="handleClose">
		<a-spin wrapper-class-name="tunjai-page-loading" :spinning="loading" size="large" :tip="$t('onfield_return_modal.pageLoading.tip')">
			<div id="print-pre-return" class="return-modal">
				<div class="page-header">
					<my-page-header :title="$t('onfield_return.title', { name: serviceNumber })" />
					<div>{{ $t('onfield_return.header.page_date', { datetime: printDate }) }}</div>
				</div>

				<div class="service-detail-container">
					<div class="service-col">
						<label>{{ $t('onfield_return.header.company_info') }}</label>
						<div v-if="displayCompany" class="service-val">
							<div class="service-val-row">
								{{ displayCompany.name }}
							</div>
							<div class="service-val-row">
								{{
									(displayCompany.address ? displayCompany.address + ' ' : '') + displayCompany.province + ' ' + displayCompany.postal_code
								}}
							</div>
							<div v-if="displayCompany.contact_phone" class="service-val-row">
								<PhoneLink :view-only="true" :value="displayCompany.contact_phone" />
							</div>
						</div>
					</div>

					<div class="service-col">
						<label>{{ $t('onfield_return.header.drone') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ $t('onfield_return.header.drone.serial', { serial: droneSerial }) }}
							</div>
							<div class="service-val-row">
								{{ $t('onfield_return.header.drone.pixhawk', { pixhawk: dronePixhawk }) }}
							</div>
							<div class="service-val-row">
								{{ $t('onfield_return.header.drone.model', { name: droneModelName }) }}
							</div>
						</div>
					</div>

					<div class="service-col">
						<label>{{ $t('service.field.customer') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ customerName }}
							</div>
							<div class="service-val-row">
								<PhoneLink :view-only="true" :value="customerPhone" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + customerProvince }}</span>
							</div>
						</div>
					</div>

					<div class="service-col">
						<label>{{ $t('onfield_return.header.drone_receive_date') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="droneReceiveDateTime">
									{{ droneReceiveDateTime }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>

					<div class="service-col">
						<label>{{ $t('onfield_return.header.report_by') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="openByUsername">
									{{ openByUsername }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>

					<div class="service-col">
						<label> {{ deviceOnly ? $t('onfield_return.header.devices_without_drone') : $t('onfield_return.header.devices') }}</label>
						<div class="service-val" style="display: flex; flex-direction: column">
							<div v-for="item in devices" :key="item.id">
								{{ $tenum('drone.devices', item) }}
							</div>
							<div v-if="others !== ''" class="service-val">
								{{ others }}
							</div>
						</div>
					</div>
				</div>

				<div class="checklist-row checklist-row-title checklist-row-border">
					<label class="title"> <span>1</span> {{ $t('onfield_return.return.payment') }} </label>
				</div>
				<div class="return-subsection">
					<div class="return-row">
						<label>{{ $t('service_payment.field.total_fee') }} : </label>
						<span class="return-value">{{ payment.total_fee | price }}</span>
					</div>
					<div class="return-row">
						<label>{{ $t('service_payment.field.status') }} : </label>
						<div class="return-value">
							{{ $tenum('service.payment.status', payment.status) }}
						</div>
						<div v-if="$notEmpty(payment.completed_date)" class="help">
							{{ $t('onfield_return_payment.field.completed_date.display', { date: $dayjs(payment.completed_date).format('LL') }) }}
						</div>
					</div>
					<div v-if="$notEmpty(payment.payment_remark)" class="return-row">
						<label>{{ $t('service_payment.field.payment_remark') }} : </label>
						<div v-trim class="return-value multiline" style="max-width: 800px">
							{{ payment.payment_remark }}
						</div>
					</div>
				</div>
				<div class="checklist-row checklist-row-title checklist-row-border">
					<label class="title"> <span>2</span> {{ $t('onfield_return.return.detail') }} </label>
				</div>
				<div class="return-subsection">
					<div class="return-row">
						<label>{{ $t('onfield_return.return_by') }} : </label>
						<span class="return-value">{{ returnByUserData.full_name }}</span>
					</div>
					<div class="return-row">
						<label>{{ $t('onfield_return.return_to') }} : </label>
						<div class="return-value">
							{{ customerName }}
						</div>
					</div>
					<div class="return-row">
						<label>{{ $t('onfield_return.return_date') }} : </label>
						<div class="return-value">
							{{ returnValue.return_date }}
						</div>
					</div>
					<div class="return-row">
						<label>{{ $t('onfield_return.return_method') }} : </label>
						<div class="return-value">
							{{ $tenum('drone.return', returnValue.delivery_method) }}
						</div>
					</div>
				</div>

				<div v-if="returnValue.return_type === 'refer'" class="refer-text">
					{{ $t('onfield_return.refer', { sender: displayCompany.name, receiver: referCompany }) }}
				</div>
				<a-checkbox @change="handleAccept">{{ $t('onfield_return.footer.consent.acceptance') }}</a-checkbox>
				<div class="signature">
					<div>
						<div v-if="!signData.customer.img" id="customer" class="sign-area" v-html="$t('onfield_return.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.customer.img" class="signature-img" />
						</div>
						<div v-if="!signData.customer.name" id="customerName" class="signature-item" v-html="$t('onfield_return.footer.name')" />
						<div v-else class="signature-item" v-html="`(${signData.customer.name})`" />
						<div class="signature-item" v-html="$t('onfield_return.footer.signature.customer')" />
						<div class="sign-button">
							<a-button id="customerBt" @click="openSignModal($refs.customerRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
					<div>
						<div v-if="!signData.inspector.img" id="inspector" class="sign-area" v-html="$t('onfield_return.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.inspector.img" class="signature-img" />
						</div>
						<div v-if="!signData.inspector.name" id="inspectorName" class="signature-item" v-html="$t('onfield_return.footer.name')" />
						<div v-else class="signature-item" v-html="`(${signData.inspector.name})`" />
						<div class="signature-item" v-html="$t('onfield_return.footer.signature.inspect_by')" />
						<div class="sign-button">
							<a-button id="inspectorBt" @click="openSignModal($refs.inspectorRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
					<div>
						<div v-if="!signData.receiver.img" id="receiver" class="sign-area" v-html="$t('onfield_return.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.receiver.img" class="signature-img" />
						</div>
						<div v-if="!signData.receiver.name" id="receiverName" class="signature-item" v-html="$t('onfield_return.footer.name')" />
						<div v-else class="signature-item" v-html="`(${signData.receiver.name})`" />
						<div class="signature-item" v-html="$t('onfield_return.footer.signature.deliver_by')" />
						<div class="sign-button">
							<a-button id="receiverBt" @click="openSignModal($refs.receiverRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
					<div>
						<div v-if="!signData.approver.img" id="approver" class="sign-area" v-html="$t('onfield_return.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.approver.img" class="signature-img" />
						</div>
						<div v-if="!signData.approver.name" id="approverName" class="signature-item" v-html="$t('onfield_return.footer.name')" />
						<div v-else class="signature-item" v-html="`(${signData.approver.name})`" />
						<div class="signature-item" v-html="$t('onfield_return.footer.signature.approve_by')" />
						<div class="sign-button">
							<a-button id="approverBt" @click="openSignModal($refs.approverRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
				</div>
				<div class="page-remark">
					{{ $t('onfield_return.footer.remark') + (returnValue.remark ? returnValue.remark : '') }}
				</div>
			</div>

			<div class="page-action-right next-button">
				<a-button type="primary" @click="handleSave">
					{{ $t('action.save') }}
				</a-button>
			</div>
			<SignModal ref="customerRef" @save="handleSign($event, 'customerRef')" />
			<SignModal ref="inspectorRef" @save="handleSign($event, 'inspectorRef')" />
			<SignModal ref="receiverRef" @save="handleSign($event, 'receiverRef')" />
			<SignModal ref="approverRef" @save="handleSign($event, 'approverRef')" />
		</a-spin>
	</a-modal>
</template>

<script>
import { mapState } from 'vuex'
import PhoneLink from '@components/common/PhoneLink.vue'
import axios from 'axios'
import ApiError from '@utils/errors/ApiError'
import SignModal from '@components/common/SignModal.vue'
import { Checkbox } from 'ant-design-vue'
import {RETURN_TYPE} from "@utils/serviceUtil"

export default {
	components: {
		PhoneLink,
		SignModal,
		'a-checkbox': Checkbox,
	},

	props: {
		payment: {
			type: null,
			default: () => {},
		},
		returnByUserData: {
			type: null,
			default: () => {},
		},
		returnValue: {
			type: null,
			default: () => {},
		},
	},

	data() {
		return {
			isAccepted: false,
			visible: false,
			loading: false,
			droneReceiveDateTime: '',
			deviceOnly: false,
			serviceId: '',
			serviceNumber: '',
			droneSerial: '',
			dronePixhawk: '',
			droneModelName: '',
			customerName: '',
			customerPhone: '',
			customerProvince: '',
			printDate: '',
			openByUsername: '',
			devices: [],
			batteries: {},
			others: '',
			remark: '',
			referCompany: '',
			signData: {
				customer: {
					img: null,
					name: null,
				},
				inspector: {
					img: null,
					name: null,
				},
				receiver: {
					img: null,
					name: null,
				},
				approver: {
					img: null,
					name: null,
				},
			},
			isDocumentSaved: false,
		}
	},

	computed: {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		displayCompany() {
			return this.companies.find((c) => c.id == this.currentCompany.id)
		},
	},

	methods: {
		_loadPreServcie() {
			this.loading = true
			axios
				.get(`/api/services/${this.serviceId}/pre-service`)
				.then((response) => {
					if (response.data.data.pre_service.devicesData) {
						this.devices =
							response.data.data.pre_service.devicesData.devices.length === 0
								? [`$t(onfield_return.header.devices_unspecified)`]
								: response.data.data.pre_service.devicesData.devices
						this.others = response.data.data.pre_service.devicesData.others
						this.batteries = response.data.data.pre_service.devicesData.batteries
					} else {
						this.devices = [`$t(onfield_return.header.devices_unspecified)`]
					}
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
					this.visible = false
				})
				.finally(() => {
					this.loading = false
				})
		},
		openSignModal(ref) {
			ref.openSignModal()
		},
		handleSign(val, currentSigner) {
			switch (currentSigner) {
				case 'customerRef':
					this.signData.customer.img = val.img
					this.signData.customer.name = val.name
					break
				case 'inspectorRef':
					this.signData.inspector.img = val.img
					this.signData.inspector.name = val.name
					break
				case 'receiverRef':
					this.signData.receiver.img = val.img
					this.signData.receiver.name = val.name
					break
				case 'approverRef':
					this.signData.approver.img = val.img
					this.signData.approver.name = val.name
			}
		},
		openModal(data) {
			this.visible = true
			this.serviceId = data.serviceId
			this.deviceOnly = data.isDeviceOnly
			this.serviceNumber = data.serviceNumber
			this.droneSerial = data.droneSerial
			this.dronePixhawk = data.dronePixhawk
			this.droneModelName = data.droneModelName
			this.customerName = data.customerName
			this.customerPhone = data.customerPhone
			this.customerProvince = data.customerProvince
			this.openByUsername = data.openBy
			this.referCompany = data.referCompany
			const currentDateTime = new Date()
			this.printDate = currentDateTime.toLocaleDateString() + ':' + currentDateTime.toLocaleTimeString()
			const createdDateTime = this.$dayjs(data.createdDateTime)
			this.droneReceiveDateTime = createdDateTime.format('YYYY-MM-DD') + ' เวลา ' + createdDateTime.format('HH:mm')
			this._loadPreServcie()
		},
		handleClose() {
			if (this.isDocumentSaved) {
				this.visible = false
				return
			}
			this.$confirm({
				title: this.$t('service.onsite.form.action.update.confirm.title'),
				content: h => <div>{this.$t('service.onsite.form.action.update.confirm.message')}
												<span style="color:red;"><u>{this.$t('service.onsite.form.action.close.confirm.warning')}</u></span>
												{this.$t('service.onsite.form.action.close.confirm.yes_or_no')}
											</div>,
				okText: this.$t('common.ok'),
				cancelText : this.$t('common.cancel'),
				maskClosable: true,
				onOk: () => {
					this.visible = false
				},
			})
		},
		handleAccept(e) {
			this.isAccepted = e.target.checked
		},
		handleSave() {
			const signData = this.signData
			if (signData.customer.img  && signData.receiver.img) {
				this.loading = true
				this.$emit('save', {
					signData,
					droneReceiveDateTime: this.droneReceiveDateTime,
					accepted: this.isAccepted,
					receiverCompany: this.returnValue.return_type === RETURN_TYPE.REFER ? this.referCompany : null,
					devices: this.devices,
					others: this.others,
					batteries: this.batteries,
				})
				this.isDocumentSaved = true
			} else {
				this.$message.error(this.$t('onfield_return.sign_warning'))
			}
		},
	},
}
</script>

<style lang="less" scoped>
@part-break-screen: @screen-lg;

.return-modal {
	height: 600px;
	overflow: auto;
	overscroll-behavior: none;
	div {
		font-size: 0.7rem;
	}
}
.page-header {
	display: flex;
	flex-basis: 100%;
	flex-direction: row;
}
.service-detail-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	background-color: @white;
	margin-bottom: 8px;
	border-radius: @border-radius-base;
	.service-col {
		flex-basis: 50%;
		display: flex;
		flex-wrap: nowrap;
		min-width: 0;
		border: 1px solid @gray-4;
		> label {
			font-family: @font-family-title;
			color: @primary-color;
			min-width: 100px;
			flex-basis: 100px;
			background-color: @gray-3;
			padding: 4px 8px;
		}
		> .service-val {
			padding: 4px 8px;
			min-width: 0;
			.service-val-row {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.return-row {
	margin-top: 12px;
	margin-bottom: 12px;
	.mobile {
		margin-bottom: 24px;
	}
	> label {
		color: @info-color;
		width: 150px;
		text-align: right;
		margin-right: 8px;
		display: inline-block;
		.mobile & {
			display: block;
			text-align: left;
			margin-bottom: 2px;
			width: auto;
		}
	}
	&.full > label {
		text-align: left;
		display: block;
		margin-bottom: 4px;
	}
	.return-value {
		display: inline-block;
		color: @text-color;
	}
	.return-input {
		display: inline-block;
		.mobile & {
			display: block;
		}
	}
	.return-time-range {
		display: block;
		margin-top: 8px;
		margin-bottom: 4px;
	}
	.help {
		color: @text-muted;
		margin-top: 2px;
		font-size: 0.95em;
		margin-left: 158px;
		.mobile & {
			margin-left: 0;
		}
	}
}
.consent {
	margin-top: 16px;
}
.signature {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	padding: 10px;
	margin-top: 8px;
	margin-bottom: 0px;

	.signature-item {
		display: flex;
		align-items: center;
		flex-direction: column;
		position: relative;
	}
	.sign-button {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.signature-img {
	display: block;
	width: 100%;
}
.sign-area {
	display: block;
	align-items: center;
	width: 123.16px;
	height: 40px;
}
.page-remark {
	display: flex;
	margin-top: 8px;
	justify-content: left;
	align-items: left;
}
.refer-text {
	color: @primary-color;
	margin-bottom: 2rem;
}
</style>
